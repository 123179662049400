.logo {
    text-align : center ;
    border-bottom : solid 3px #F2F2F2 ;
    margin : 10px 10px ;
    font-weight : bold ;
    font-size : 1.2rem 
}
aside{
    height : 100vh !important  ;
    box-shadow :  -4px 3px 17px #00000029 ;
    width: 260px !important;
    min-width: 260px !important;
    max-width: 260px !important;
    /* width : 247px !important ; 
    min-width : 247px !important ; 
    max-width : 247px !important ;  */
}
.ant-layout-sider-collapsed , .ant-layout-sider-collapsed .ant-menu .ant-menu-light{
    width : 80px !important ; 
    min-width : 80px !important ; 
    max-width : 80px !important ; 
    overflow-y: auto;
}
.ant-menu-root{
   height : 100% !important ;
}
.site-layout-background{
    min-height : 100vh !important;
}
.EmployeeName{
    text-align: center;
    font: Medium 20px/26px GE SS Two;
    letter-spacing: 0px;
    color: #0A154D;
}
.userPhoto{
    border-radius : 50% ;
}
.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}
.ant-layout-sider{
    background-color : #fff;
}
.ant-layout{
    background-color : #f1f1f1 !important ;
}
.contentContainer{
    margin-right : 260px ;
    /* min-height : 100vh ; */
}
.ant-menu-item{
    display: flex ;
    align-items : center ;
    cursor : pointer ;
    margin-bottom: 0 !important;
}
.logo img { width: 150px !important; height: 150px !important ; }
.ant-badge-not-a-wrapper .ant-badge-count {
    background: #DD1C23 0% 0% no-repeat padding-box;
    box-shadow: 5px 6px 9px #DD1C234D;
    font-family: Dubai;
    color: #FFFFFF;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
    color: #FFFFFF;
    font-size: 16px;
}
.MenuItem {  width: 100%; }
.MenuItem a span , .MenuItem i, .MenuItem div span {
    color:#8A8A8A;
    font-size : 17px !important ;
   
}
.MenuItem a span{
    padding : 0px 10px 0px 10px ;
}
.MenuItem i{
    padding : 0px 5px 0px 15px ;
}
.ant-menu-item-active ,.ant-menu-item-selected{
    background : #fff !important ;
}
.ant-menu-item-selected  a span {
    color : #0A154D !important  ;
}
.ant-menu-item-selected  i {
    color : #DD1C23 !important  ;
    font-size : 1.5rem !important ;
}
.ant-menu-title-content{
    text-align: right !important;
}
.ant-menu-item::after{
    border-right : 3px solid #DD1C23  !important ;
}
.img-fluid{
    max-width:100%;
    max-height : 100% ;
}
@media(max-width:576px){
    .contentContainer{
        margin-right : 75px !important ;
    }
}
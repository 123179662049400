.App{
    font-family: Arial, Helvetica, sans-serif ;
}
.BG{
   background-image : url("./Assets/Images/personalInformationBG.svg");
   background-size: auto  ;
   background-repeat: no-repeat;
   background-position-y: bottom;
   min-height : 100vh ;
}
#Searchheader {
   position: absolute;
   top: 20vh;
   left: 0;
   right: 20%;
}
@font-face {
    font-family: myFont;
    src: url(GE_SS_Two_Medium.otf);
 }
 @font-face {
    font-family: Gotham;
    src: url(Gotham-Bold.otf);
 }
 @font-face {
    font-family: Dubai;
    src: url(Dubai-Medium.woff);
 }

 
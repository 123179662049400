.Style2_MainContentLogin{
  background-image: url('../../Assets/Images/Group 565@2x.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
 }

 .style2_overlay{
  background-image: url('../../Assets/Images/Group 565@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color:white;
  height: 100vh;
  width: 100%;
  overflow: hidden !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


#logoo{
  margin: auto;
  border-radius: 10px;
}
.LogoDiv{
    background-image: url('../../Assets/Images/Group 474.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color:white;
    width:100% !important;
    height: 5rem !important;
    border-radius: 10px;
    margin-bottom: 20px;
}
.style2_loginForm {
  width: 476px !important;
  height: 411px !important;
  top: 0% !important;
  padding: 3rem;
  position: relative;
  background: white;
  border-radius: 10px;
}
.ant-input-affix-wrapper{
width: 100%;
height: 50px;
background: rgba(242, 242, 242, 1) 0% 0% no-repeat padding-box;
border-radius: 10px;
opacity: 1;
}
.ant-input{
  width: 100%;
  background: rgba(242, 242, 242, 1) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-family: Dubai;
  }
  #loginForm .ant-btn-primary {
    width: 100%;
    background: #0A154D 0% 0% no-repeat padding-box;
    box-shadow: 4px 7px 10px #11206D40;
    border-radius: 10px;
    opacity: 1;
    height: 50px;

    font: Bold 22px/29px myFont;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 10px;
  }
  .firstlogo {
    text-align: center;
    height: 400px;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
  }
  .ant-form-item-explain{
    padding: .5rem!important;
  }
  
